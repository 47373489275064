import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { EURCurrency } from '@travauxlib/shared/src/components/EURCurrency';
import { toast } from '@travauxlib/shared/src/components/Notifications';
import { SUIVI_CHANTIERS_KEY } from '@travauxlib/shared/src/features/SuiviChantier/api/createSuiviChantierHistoryList';
import { SuiviLot } from '@travauxlib/shared/src/features/SuiviChantier/types';
import { request } from '@travauxlib/shared/src/utils/request';

import { useGetClientCustomerSatisfaction } from 'features/EspaceClient3/api/useGetClientCustomerSatisfaction';
import { PROJECTS_KEY } from 'features/EspaceClient3/api/useProjects';
import { routeCustomerSatisfaction, routePaiements } from 'features/EspaceClient3/utils';

import { useDeleteSuiviChantierDraft } from './useDeleteSuiviChantierDraft';

type Payload = {
  chantierUuid: string;
  suiviChantierUuid: string;
  lots: SuiviLot[];
  montantALibererTTC: number;
  globalPourcentageAvancement: number;
};

type APIResponse = {
  montantLibereTTC: number;
  proCompanyName: string;
};

const handleValidateSuiviChantier = ({
  chantierUuid,
  suiviChantierUuid,
  ...rest
}: Payload): Promise<APIResponse> => {
  const requestURL = `${APP_CONFIG.apiURL}/client/chantiers/${chantierUuid}/suivi-chantier/${suiviChantierUuid}`;

  return request(requestURL, {
    method: 'POST',
    body: rest,
  });
};

export const useValidateSuiviChantier = (uuid: string): typeof handleValidateSuiviChantier => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const deleteSuiviChantierDraft = useDeleteSuiviChantierDraft();
  const { clientCustomerSatisfaction } = useGetClientCustomerSatisfaction(uuid);

  const { mutateAsync: createSuiviChantier } = useMutation({
    mutationFn: handleValidateSuiviChantier,
    onSuccess: (
      { proCompanyName },
      { montantALibererTTC, chantierUuid, globalPourcentageAvancement },
    ) => {
      toast.success(
        montantALibererTTC > 0 ? (
          <>
            <EURCurrency amount={montantALibererTTC} /> ont bien été libérés au profit de
            l'entreprise {proCompanyName}
          </>
        ) : (
          <>
            Aucune libération de fonds n'a été effectuée. L'entreprise <b>{proCompanyName}</b> a été
            informée de vos ajustements sur l'état d'avancement du chantier.
          </>
        ),
      );
      deleteSuiviChantierDraft(chantierUuid);
      queryClient
        .resetQueries({ queryKey: [SUIVI_CHANTIERS_KEY] })
        .then(() => queryClient.removeQueries({ queryKey: [SUIVI_CHANTIERS_KEY] }));
      queryClient.invalidateQueries({ queryKey: [PROJECTS_KEY] });
      const condition =
        globalPourcentageAvancement > 40
          ? globalPourcentageAvancement < 60
            ? 'avancementAbove40'
            : 'avancementAbove60'
          : undefined;

      const hasRepliedToThisStage = clientCustomerSatisfaction.some(
        clientCustomerSatisfaction => clientCustomerSatisfaction.condition === condition,
      );
      if (condition && !hasRepliedToThisStage) {
        navigate(`../../../${routeCustomerSatisfaction}`, {
          state: {
            feature: 'suivi-chantier',
            condition,
          },
        });
      } else {
        navigate(`../../../${routePaiements}/${chantierUuid}`);
      }
    },
    onError: () => {
      toast.error('Il y a eu un problème, veuillez contacter le support');
    },
  });

  return createSuiviChantier;
};
